import React from "react"
import "./ProductComponents.scss"

export default function ProductComponents({
  productcomponents,
  TotalProductComponents,
  ...props
}) {
  const [active, setActive] = React.useState(productcomponents[0])

  console.log(TotalProductComponents)
  return (
    <div className="columns productComponentWrapper">
      <div className="is-half column">
        <ul>
          {productcomponents.map((component, index) => {
            return (
              <li
                onClick={() => setActive(component)}
                className={
                  active.Name !== component.Name
                    ? "productComponentNames"
                    : "productComponentNames productComponentNames_active"
                }
              >
                {component.Name}
              </li>
            )
          })}
        </ul>
      </div>
      <div className="column is-half">
        <img
          style={{ width: 650, height: 327 }}
          src={active.Product_Component_Image.publicURL}
        />
        <p className="ProductComponentDescription">{active.Description}</p>
        <h2 className="ProductComponentFeatureHeader">Key Features</h2>
        <ul>
          {TotalProductComponents.filter(
            component => component.Name === active.Name
          )[0].productcomponentfeatures.map(cmp => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="icon Green">
                  <i className="fas fa-certificate"></i>
                </span>
                <li className="productfeature">{cmp.Name}</li>
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
