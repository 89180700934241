import React from "react"
import Hero from "./Hero/Hero"
import Features from "./Features/Features"
import ProductDetails from "./ProductDetails/ProductDetails"
import UseCases from "./UseCases/UseCases"
import Layout from "../layout"
import SEO from "../seo"
import ProductDescription from "./ProductDescription/ProductDescription"
import ProductFeature from "./ProductFeature/ProductFeature"
import ProductComponents from "./ProductComponents/ProductComponents"
export default function ProductPage({ pageContext }) {
  console.log(pageContext)
  const product = pageContext.item.node
  return (
    <Layout>
      <SEO title={product.header} />
      <Hero
        name={product.header}
        image={product.featureImage.publicURL}
        // description={product.Description}
        // callToAction1={product.Call_To_Action_1}
        // callToAction2={product.Call_To_Action_2}
      />

      <ProductDescription
        name={product.header}
        description={product.description}
        icon={product.DescriptionIcon.publicURL}
      ></ProductDescription>

      <ProductFeature
        productfeatures={product.productfeatures}
        name={product.header}
        image={product.featureSectionImage.publicURL}
        NoproductComponents={product.productcomponents.length === 0}
      ></ProductFeature>

      {product.productcomponents.length !== 0 ? (
        <ProductComponents
          productcomponents={product.productcomponents}
          TotalProductComponents={pageContext.data.nodes}
        ></ProductComponents>
      ) : null}
      {/* <Features
        features={product.productfeatures}
        image={product.Features_Image.publicURL}
        description={product.Features_Description}
      /> */}
      {/* <ProductDetails
        tagline={product.Tagline}
        subtitle={product.Subtitle}
        components={product.productcomponents}
      /> */}
      {/* <UseCases usecases={product.productusecases} /> */}
    </Layout>
  )
}
