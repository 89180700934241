import React from "react"
import "./ProductFeature.scss"

export default function ProductFeature({
  name,
  productfeatures,
  image,
  NoproductComponents,
  ...porps
}) {
  return (
    <div className="skewed_featuredsection">
      <div className="unskewed">
        <h2 className="title has-text-centered-fullhd">
          {"How " + name + " works"}
        </h2>
        <div className="columns" style={{ marginTop: 50 }}>
          <div className="column is-half">
            <h3 style={{ fontWeight: 600, fontSize: "1.2rem" }}>
              It enables enterprises to :
            </h3>
            <ul style={{ listStyle: "disc" }}>
              {productfeatures.map(feature => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <li>{feature.Name}</li>

                    {/* <span style={{ marginLeft: 10 }}>-</span> */}
                  </div>
                )
              })}
            </ul>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="column is-half"
          >
            <img src={image} style={{ maxHeight: 400 }} />
          </div>
        </div>
      </div>
      {NoproductComponents ? (
        <div
          style={{
            backgroundColor: "#fafafa",
            width: "100%",
            height: "55px",
            width: "50%",
            position: "absolute",
            bottom: "-60px",
            right: "0px",
          }}
        ></div>
      ) : null}
    </div>
  )
}
