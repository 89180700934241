import React from "react"
import "./ProductDescription.scss"

export default function ProductDescription({
  name,
  description,
  icon,
  ...props
}) {
  return (
    <section className="section product-page-description">
      <div className="columns" style={{ position: "relative" }}>
        <div className="column is-half is-full-mobile">
          <h3 className="title">{"What is " + name + " ?"}</h3>
          <p>{description}</p>
        </div>
        <div className="column is-half is-full-mobile icon_box">
          <img style={{ width: 200, height: 160 }} src={icon} alt="icon" />
        </div>
      </div>
    </section>
  )
}
