import React from "react"
import "./Hero.scss"
// import PrimaryAnchor from "../../PrimaryAnchor/PrimaryAnchor"
// import SecondaryAnchor from "../../SecondaryAnchor/SechondaryAnchor"
import PropTypes from "prop-types"
export default function Hero({
  name,
  image,
  // description,
  // callToAction1,
  // callToAction2,
}) {
  return (
    <section id="#producthero" className="hero product-page-hero">
      <div className="hero-body">
        <div className="container">
          <div className="product-page-hero-wrapper">
            <h3 className="title has-text-centered">{name}</h3>
          </div>
        </div>
      </div>
      <img className="hero_image" src={image} alt="" />
    </section>
  )
}

Hero.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  callToAction1: PropTypes.string,
  callToAction2: PropTypes.string,
}
